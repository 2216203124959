import Client from '../libraries/Client.js';
import { setCurrentInstance } from './currentInstance.js';
import getClient from '../functions/getClient.js';

export default {
  inject: {
    useApiClients: {
      default: null
    },

    // Backwards compatibility
    $apiClient: {
      default: null
    }
  },

  methods: {
    api_obtainClient(apiSpec, clientName = null) {

      // Buscar prop :api-client=""  o  :api-NAMEDAPINAME=""
      const apiPropName = clientName ? `api-${clientName}` : 'api-client';
      if (this?.$attrs?.[apiPropName]) {
        return this.$attrs[apiPropName];
      }

      // Buscar en inject
      if (this.useApiClients?.length) {
        let targetType = apiSpec?.type;
        let found = this.useApiClients.find(client => client.type == targetType);
        if (found) {
          return found;
        }
      }

      /// METODOS PARA BACKWARDS COMPATIBILITY
      // Buscar en inject
      if (this.$apiClient) {
        if (typeof this.$apiClient == 'function') {
          return this.$apiClient();
        }
        return this.$apiClient;
      }

      // Fallback: buscar en el "STORE"  (esto se debe deprecar pronto)
      if (this?.$store?.state?.api) {
        return this.$store.state.api;
      }

      // Last-ditch: usar el PRIMERO de useApiClients (si lo hay)
      if (this?.useApiClients?.[0]) {
        return this.useApiClients[0];
      }

      console.warn('api_obtainClient: Could not find client', apiSpec, clientName);
      return new Client;
    },


    api_buildObject(apiOptions, defaultClient, clientName = null) {

      if (typeof apiOptions == 'function') {
        return apiOptions.call(this, defaultClient);
      }

      if (typeof apiOptions == 'object') {
        /*
        Declaraciones de $api de la forma:

        $api: {
          type: objApiType,
          wrappers: [ ... wrapper fxs ...]
        }

        son un shortcut para:

        $api() {
          const client = getClient(objApiType);
          return {
            ...wrapperN(client),
            ...wrapperN(client),
            ...wrapperN(client),
          }
        }
        */
        if (apiOptions?.type) {
          const client = getClient(apiOptions.type, clientName);
          let retval = {};
          (apiOptions?.wrappers || []).forEach(wrapper => {
            retval = { ...retval, ...wrapper(client) }
          });

          return retval;
        }


        const retval = {};
        for (let prop in apiOptions) {
          if (Object.prototype.hasOwnProperty.call(apiOptions, prop)) {
            retval[prop] = this.api_buildObject(apiOptions[prop], defaultClient, prop);
          }
        }

        return retval;
      }

      return null;

    }
  },

  computed: {
    $httpClient() {
      // get default client
      return this.api_obtainClient(null);
    },

    $api() {
      let apiOptions = this.$options?.$api;

      if (this.$options?.api) {
        // console.warn('useApi mixin: Deprecated "api" property in component. Use "$api" instead', this);
        apiOptions = this.$options.api;
      }

      if (!apiOptions) {
        return null;
      }

      setCurrentInstance(this);
      const retval = this.api_buildObject(apiOptions, this.$httpClient);
      setCurrentInstance(null);

      return retval;
    }
  }
}