import Client from '../libraries/Client.js';
import { currentInstance } from '../mixins/currentInstance.js';

/*
getClient() retorna un objeto HTTP CLIENT

getClient('https://colegio.phidias.co')


Dentro de un componente que usa el mixin useApi,
los llamados a getClient desde la funcion $api() buscan el component

{
  name: 'MyCoolComponent',
  mixins: [useApi],

  $api() {
    const client1 = getClient({type: 'phidias'});
    return {
      base: client1,
      ...billing(client1),
      ...academics(client1)
    }
  }
}
*/
export default function (clientParams = null, clientName = null) {
  // Si se invoca dentro de un componente ....
  if (currentInstance) {
    return currentInstance.api_obtainClient(clientParams, clientName);
  }

  return new Client(clientParams);
}